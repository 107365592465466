$(document).ready(function () {
  var linkScroll = $(".scrollable");

  linkScroll.click(function (e) {
    e.preventDefault();
    $("body, html").animate(
      {
        scrollTop: $(this.hash).offset().top - 90,
      },
      500
    );
  });
});
